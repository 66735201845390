import { faStripeS } from "@fortawesome/free-brands-svg-icons";
import { faClock, faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { faClipboardList, faCode, faCreditCard, faLemon, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import {
  Chart as ChartJS,
  Filler,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  TimeSeriesScale,
  Title,
} from "chart.js";
import classNames from "classnames";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";
import { rgba } from "polished";
import React, { useCallback, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { Line } from "react-chartjs-2";
import { merge } from "ts-deepmerge";

import { GetTeamDetailsAdminResponse, HistoryChartDataset, ListTeamsAdminResponseItem } from "../backend";
import AppEnvBadge from "../components/AppEnvBadge";
import ChartContainer from "../components/ChartContainer";
import ModalSection from "../components/ModalSection";
import TableCard from "../components/TableCard";
import TableCellDropdown from "../components/TableCellDropdown";
import Tooltip from "../components/Tooltip";
import { useGlobal } from "../contexts/GlobalContext";
import { getChartOptions } from "../utils/charts";
import { getColor } from "../utils/colors";
import "./AdminTeamDetailsModal.css";

ChartJS.register(Filler, LinearScale, TimeSeriesScale, LineController, LineElement, PointElement, Title);

type HistoryChartProps = {
  data?: HistoryChartDataset | null;
  label?: string;
};

function HistoryChart({ data, label }: HistoryChartProps) {
  let chart = <></>;
  if (data) {
    const chartOptions = merge(
      getChartOptions({
        labels: data.days,
        tooltipTotalFooter: false,
      }),
      {
        scales: { x: { display: false }, y: { ticks: { precision: 0 } } },
        tooltips: { intersect: false },
        interaction: { mode: "index" as const, intersect: false },
      },
    );
    const color = getColor("primary");
    const chartData = {
      labels: data.days,
      datasets: [
        {
          data: data.counts,
          label: label,
          borderColor: color,
          backgroundColor: rgba(color, 0.2),
          borderWidth: 1,
          fill: true,
          pointStyle: false as const,
        },
      ],
    };
    chart = <Line data={chartData} options={chartOptions} />;
  }
  return (
    <div className="border rounded px-4 py-2 h-100">
      <div className="small text-muted text-center mb-2">{label}</div>
      <ChartContainer height={80}>{chart}</ChartContainer>
    </div>
  );
}

type AdminTeamDetailsModalTabProps = {
  team?: ListTeamsAdminResponseItem;
  teamDetails?: GetTeamDetailsAdminResponse;
};

function AdminTeamDetailsModalAppsTab({ teamDetails }: AdminTeamDetailsModalTabProps) {
  return (
    <>
      {teamDetails?.apps.map((app, i) => (
        <ModalSection
          key={app.id}
          title={app.name}
          titleExtra={`ID ${app.id}`}
          className={classNames({ "mt-6": i > 0 })}
        >
          <Row className="g-4">
            <Col xs={12} lg={4}>
              <HistoryChart data={app.instance_count_history} label="Instances" />
            </Col>
            <Col xs={12} lg={4}>
              <HistoryChart data={app.consumer_count_history} label="Consumers" />
            </Col>
            <Col xs={12} lg={4}>
              <HistoryChart data={app.logged_requests_history} label="Logged requests" />
            </Col>
          </Row>

          <TableCard responsive borderTop={false} hover={false} className="align-middle mt-4">
            <thead>
              <tr>
                <th className="ps-4" style={{ width: 170 }}>
                  Environment
                </th>
                <th style={{ width: 100 }}>ID</th>
                <th style={{ width: 180 }}>Last sync</th>
                <th style={{ width: 180 }}>Client</th>
                <th>Versions</th>
              </tr>
            </thead>
            <tbody>
              {app.envs.map((env) => {
                const lastSyncAt = env.last_sync_at ? DateTime.fromISO(env.last_sync_at) : undefined;
                return (
                  <tr key={env.id}>
                    <td className="ps-4">
                      <AppEnvBadge env={env} online={env.online} />
                    </td>
                    <td>{env.id}</td>
                    <td>
                      <span title={lastSyncAt?.toLocaleString(DateTime.DATETIME_FULL)} className="text-nowrap">
                        {lastSyncAt?.toRelative()}
                      </span>
                    </td>
                    <td>{env.client}</td>
                    <td>
                      <div className="d-flex flex-wrap gap-2">
                        {Object.entries(env.versions || {})
                          .filter(([key]) => key !== "app")
                          .map(([key, value]) => (
                            <div
                              key={key}
                              className="border rounded small text-nowrap"
                              style={{
                                paddingLeft: "0.375rem",
                                paddingRight: "0.5rem",
                                paddingTop: "0.125rem",
                                paddingBottom: "0.125rem",
                              }}
                            >
                              {key} = {value}
                            </div>
                          ))}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </TableCard>
        </ModalSection>
      ))}
    </>
  );
}

function AdminTeamDetailsModalUsersTab({ teamDetails }: AdminTeamDetailsModalTabProps) {
  return (
    <>
      <Row className="g-4">
        <Col xs={12}>
          <HistoryChart data={teamDetails?.login_history} label="Active users" />
        </Col>
      </Row>

      <TableCard responsive borderTop={false} hover={false} className="align-middle mt-4">
        <thead>
          <tr>
            <th className="ps-4">User</th>
            <th style={{ width: 100 }}>ID</th>
            <th>Role</th>
            <th>Last login</th>
            <th>Timezone</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          {teamDetails?.users.map((user) => {
            const lastLoginAt = user.last_login_at ? DateTime.fromISO(user.last_login_at) : undefined;
            return (
              <tr key={user.id}>
                <td className="ps-4">
                  <div>{user.name}</div>
                  <div className="small text-muted">{user.email}</div>
                </td>
                <td>
                  <Tooltip tooltip={user.auth0_user_id}>
                    <span>{user.id}</span>
                  </Tooltip>
                </td>
                <td>
                  {capitalize(user.role)}
                  {user.billing_user && (
                    <span className="ms-2 text-secondary small">
                      <FontAwesomeIcon icon={faCreditCard} />
                    </span>
                  )}
                </td>
                <td>
                  <span title={lastLoginAt?.toLocaleString(DateTime.DATETIME_FULL)} className="text-nowrap">
                    {lastLoginAt?.toRelative()}
                  </span>
                </td>
                <td>{user.timezone}</td>
                <td>{user.source}</td>
              </tr>
            );
          })}
        </tbody>
      </TableCard>
    </>
  );
}

function AdminTeamDetailsModalSubscriptionsTab({ teamDetails }: AdminTeamDetailsModalTabProps) {
  return (
    <TableCard responsive borderTop={false} hover={false} className="align-middle mt-4">
      <thead>
        <tr>
          <th className="ps-4">Subscription</th>
          <th style={{ width: 100 }}>ID</th>
          <th>Status</th>
          <th>Created</th>
          <th>Renews</th>
          <th>Ends</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {teamDetails?.subscriptions.map((sub) => {
          const createdAt = sub.created_at ? DateTime.fromISO(sub.created_at) : undefined;
          const renewsAt = sub.renews_at ? DateTime.fromISO(sub.renews_at) : undefined;
          const endsAt = sub.ends_at ? DateTime.fromISO(sub.ends_at) : undefined;
          return (
            <tr key={sub.id}>
              <td className="ps-4">{sub.plan}</td>
              <td>{sub.id}</td>
              <td>{capitalize(sub.status.replaceAll("_", " "))}</td>
              <td>
                <span title={createdAt?.toLocaleString(DateTime.DATETIME_FULL)} className="text-nowrap">
                  {createdAt?.toRelative()}
                </span>
              </td>
              <td>
                <span title={renewsAt?.toLocaleString(DateTime.DATETIME_FULL)} className="text-nowrap">
                  {renewsAt?.toRelative()}
                </span>
              </td>
              <td>
                <span title={endsAt?.toLocaleString(DateTime.DATETIME_FULL)} className="text-nowrap">
                  {endsAt?.toRelative()}
                </span>
              </td>
              <TableCellDropdown>
                <Dropdown.Item
                  as="button"
                  disabled={!sub.stripe_subscription_url}
                  onClick={() => {
                    if (sub.stripe_subscription_url) window.open(sub.stripe_subscription_url, "_blank");
                  }}
                >
                  <FontAwesomeIcon icon={faStripeS} fixedWidth className="text-secondary" />
                  Open in Stripe
                </Dropdown.Item>
                {!!sub.lmsqueezy_subscription_url && (
                  <Dropdown.Item
                    as="button"
                    onClick={() => {
                      if (sub.lmsqueezy_subscription_url) window.open(sub.lmsqueezy_subscription_url, "_blank");
                    }}
                  >
                    <FontAwesomeIcon icon={faLemon} fixedWidth className="text-secondary" />
                    Open in Lemon Squeezy
                  </Dropdown.Item>
                )}
              </TableCellDropdown>
            </tr>
          );
        })}
      </tbody>
    </TableCard>
  );
}

function AdminTeamDetailsModalAuditLogTab({ teamDetails }: AdminTeamDetailsModalTabProps) {
  return (
    <TableCard responsive borderTop={false} hover={false} className="align-top mt-4">
      <thead>
        <tr>
          <th className="ps-4">Time</th>
          <th>Action</th>
          <th style={{ width: 600 }}>Details</th>
        </tr>
      </thead>
      <tbody>
        {teamDetails?.audit_log.map((item) => {
          const createdAt = DateTime.fromISO(item.created_at);
          return (
            <tr key={item.id}>
              <td className="ps-4 text-nowrap">
                <div>{createdAt.toLocaleString(DateTime.DATE_MED)}</div>
                <div className="small text-muted">
                  <FontAwesomeIcon icon={faClock} className="text-very-muted me-icon" />
                  {createdAt.toLocaleString(DateTime.TIME_24_WITH_SECONDS)}
                </div>
              </td>
              <td>
                <div className="text-nowrap">{item.action}</div>
                {item.user_id && (
                  <div className="small text-muted">
                    <FontAwesomeIcon icon={faUserCircle} className="text-very-muted me-1" fixedWidth />
                    {item.user_name || `ID ${item.user_id}`}
                  </div>
                )}
                {item.app_id && (
                  <div className="small text-muted">
                    <FontAwesomeIcon icon={faCode} className="text-very-muted me-1" fixedWidth />
                    {item.app_name || `ID ${item.app_id}`}
                  </div>
                )}
              </td>
              <td>
                <div className="d-flex flex-wrap gap-2">
                  {Object.entries(item.details || {}).map(([key, value]) => (
                    <div
                      key={key}
                      className="border rounded small text-nowrap"
                      style={{
                        paddingLeft: "0.375rem",
                        paddingRight: "0.5rem",
                        paddingTop: "0.125rem",
                        paddingBottom: "0.125rem",
                      }}
                    >
                      {key} = {JSON.stringify(value)}
                    </div>
                  ))}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </TableCard>
  );
}

type AdminTeamDetailsModalProps = {
  team?: ListTeamsAdminResponseItem;
  setTeam: React.Dispatch<React.SetStateAction<ListTeamsAdminResponseItem | undefined>>;
  initialTab?: string;
};

function AdminTeamDetailsModal({ team, setTeam, initialTab = "apps" }: AdminTeamDetailsModalProps) {
  const { backendClient } = useGlobal();
  const [activeTab, setActiveTab] = useState(initialTab);

  const queryParams = {
    teamId: team?.id || 0,
  };
  const query = useQuery({
    queryKey: ["adminTeamDetails", queryParams],
    queryFn: () => backendClient!.admin.getTeamDetailsAdmin(queryParams),
    enabled: !!backendClient && !!team,
    placeholderData: undefined,
  });

  useEffect(() => {
    setActiveTab(initialTab);
  }, [team]);

  const selectTab = useCallback((key: string | null) => {
    if (key) {
      setActiveTab(key);
    }
  }, []);

  const createdAt = team?.created_at ? DateTime.fromISO(team?.created_at) : undefined;
  const tabs = [
    {
      eventKey: "apps",
      title: "Apps",
      icon: faCode,
      content: <AdminTeamDetailsModalAppsTab team={team} teamDetails={query.data} />,
    },
    {
      eventKey: "users",
      title: "Users",
      icon: faUser,
      content: <AdminTeamDetailsModalUsersTab team={team} teamDetails={query.data} />,
    },
    {
      eventKey: "subscriptions",
      title: "Subscriptions",
      icon: faCreditCard,
      content:
        query.data && query.data.subscriptions.length > 0 ? (
          <AdminTeamDetailsModalSubscriptionsTab team={team} teamDetails={query.data} />
        ) : undefined,
    },
    {
      eventKey: "audit-log",
      title: "Audit log",
      icon: faClipboardList,
      content: <AdminTeamDetailsModalAuditLogTab team={team} teamDetails={query.data} />,
    },
  ].filter((tab) => !!tab.content);

  return (
    <Modal className="AdminTeamDetailsModal tabs" size="xl" show={team !== undefined} onHide={() => setTeam(undefined)}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div>{team?.name}</div>
          <div className="text-muted mt-1" style={{ fontSize: "0.875rem" }}>
            <span>ID {team?.id}</span>
            <span className="text-very-muted mx-1">/</span>
            <span title={createdAt?.toLocaleString(DateTime.DATETIME_FULL)} className="text-nowrap">
              Created {createdAt?.toRelative()}
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-1 px-0">
        <Tab.Container activeKey={activeTab} onSelect={selectTab}>
          <Nav variant="tabs">
            {tabs.map((tab) => (
              <Nav.Item key={tab.eventKey} title={tab.title}>
                <Nav.Link eventKey={tab.eventKey}>
                  <FontAwesomeIcon icon={tab.icon} fixedWidth className="d-inline d-lg-none" />
                  <span className="d-none d-lg-inline">{tab.title}</span>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content>
            {tabs.map((tab) => (
              <Tab.Pane key={tab.eventKey} eventKey={tab.eventKey}>
                {tab.content}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
}

export default AdminTeamDetailsModal;
