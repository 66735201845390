import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import bash from "react-syntax-highlighter/dist/esm/languages/prism/bash";
import javascript from "react-syntax-highlighter/dist/esm/languages/prism/javascript";
import python from "react-syntax-highlighter/dist/esm/languages/prism/python";
import rust from "react-syntax-highlighter/dist/esm/languages/prism/rust";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

import "./CodeBlock.css";
import CopyToClipboardWithTooltip from "./CopyToClipboardWithTooltip";

SyntaxHighlighter.registerLanguage("bash", bash);
SyntaxHighlighter.registerLanguage("javascript", javascript);
SyntaxHighlighter.registerLanguage("python", python);
SyntaxHighlighter.registerLanguage("rust", rust);

export type CodeBlockProps = {
  title?: string;
  language: string;
  children: string;
};

const CodeBlock = ({ title, language, children }: CodeBlockProps) => {
  return (
    <div className="CodeBlock" title={title}>
      <SyntaxHighlighter language={language} style={vscDarkPlus}>
        {children}
      </SyntaxHighlighter>
      <span className="copy-button-container">
        <CopyToClipboardWithTooltip text={children} tooltipPlacement="left">
          <button className="copy-button">
            <FontAwesomeIcon icon={faCopy} />
          </button>
        </CopyToClipboardWithTooltip>
      </span>
    </div>
  );
};

export default CodeBlock;
