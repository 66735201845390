import {
  faCircle,
  faDoorOpen,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faPauseCircle,
  faPeopleGroup,
  faRotateRight,
  faUserNinja,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsFetching, useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Placeholder from "react-bootstrap/Placeholder";
import Stack from "react-bootstrap/Stack";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import "./AdminTeams.css";
import { ListTeamsAdminResponseItem } from "./backend";
import AppFrameworkIcon from "./components/AppFrameworkIcon";
import CustomDropdown from "./components/Dropdown";
import MainLayout from "./components/MainLayout";
import PageHeader from "./components/PageHeader";
import Pagination from "./components/Pagination";
import RefreshButton from "./components/RefreshButton";
import TableCard from "./components/TableCard";
import TableCardSearchHeader from "./components/TableCardSearchHeader";
import TableCellDropdown from "./components/TableCellDropdown";
import Tooltip from "./components/Tooltip";
import { useGlobal } from "./contexts/GlobalContext";
import AdminTeamDetailsModal from "./modals/AdminTeamDetailsModal";

function AdminTeams() {
  const { backendClient, teams, refreshTeams, setActiveTeam, setActiveTeamIdAfterRefresh } = useGlobal();
  const [filter, setFilter] = useState<string | undefined>("active");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [searchResult, setSearchResult] = useState<ListTeamsAdminResponseItem[]>();
  const [detailsModalTeam, setDetailsModalTeam] = useState<ListTeamsAdminResponseItem | undefined>();
  const navigate = useNavigate();
  const isLoading = useIsFetching() > 0;
  const itemsPerPage = 100;

  useEffect(() => {
    document.title = "Admin - Teams - Apitally";
  }, []);

  const queryParams = {
    filter,
  };
  const query = useQuery({
    queryKey: ["adminTeams", queryParams],
    queryFn: () => backendClient!.admin.listTeamsAdmin(queryParams),
    enabled: !!backendClient,
    staleTime: 60 * 60 * 1000, // 1 hour
  });

  useEffect(() => {
    if (search) {
      const searchTerms = search.toLowerCase().trim().split(/\s+/);
      setSearchResult(
        query.data?.filter((team) =>
          searchTerms.every(
            (term) =>
              team.id.toString() === term ||
              team.name.toLowerCase().includes(term) ||
              team.contact?.name.toLowerCase().includes(term) ||
              team.contact?.email.toLowerCase().includes(term) ||
              team.apps.some(
                (app) => app.id.toString() === term || app.client_id === term || app.name.toLowerCase().includes(term),
              ),
          ),
        ),
      );
    } else {
      setSearchResult(query?.data);
    }
    setPage(1);
  }, [search, query.data]);

  const refresh = async () => {
    await query.refetch();
  };

  const joinTeam = async (teamId: number) => {
    const existingTeam = teams?.find((team) => team.id === teamId);
    if (existingTeam) {
      setActiveTeam(existingTeam);
    } else if (backendClient) {
      const promise = backendClient.users.joinTeamStealth({
        requestBody: {
          team_id: teamId,
        },
      });
      toast.promise(promise, {
        pending: "Joining team...",
        success: "Team joined!",
        error: "Failed to join team.",
      });
      await promise;
      setActiveTeamIdAfterRefresh(teamId);
      await refreshTeams();
    }
    navigate("/apps");
  };

  const leaveTeam = async (teamId: number) => {
    if (backendClient) {
      const promise = backendClient.users.leaveTeam({ teamId });
      toast.promise(promise, {
        pending: "Leaving team...",
        success: "Left team!",
        error: "Failed to leave team.",
      });
      await promise;
      refreshTeams();
    }
  };

  const teamRows = searchResult?.slice(itemsPerPage * (page - 1), itemsPerPage * page).map((team) => {
    const joined = !!teams?.find((t) => t.id === team.id && !t.stealth);
    const joinedInStealth = !!teams?.find((t) => t.id === team.id && t.stealth);
    const lastLoginDate = team.last_login ? DateTime.fromISO(team.last_login) : undefined;
    const gracePeriodEndDate = team.plan_limits_grace_period_end
      ? DateTime.fromISO(team.plan_limits_grace_period_end)
      : undefined;

    return (
      <tr
        key={team.id}
        className="cursor-pointer"
        onClick={(e) => {
          const cell = (e.target as HTMLElement).closest("td");
          if (!cell?.classList.contains("TableCellDropdown")) {
            setDetailsModalTeam(team);
          }
        }}
      >
        <td style={{ width: 44 }}>
          <FontAwesomeIcon
            icon={faPeopleGroup}
            className={classNames(
              {
                "text-very-muted": !team.plan.paid,
                "text-primary": team.plan.paid && team.plan.subscription_status === "active" && !team.plan.canceled,
                "text-danger": team.plan.paid && (team.plan.subscription_status !== "active" || team.plan.canceled),
              },
              "ms-2 mt-1",
            )}
          />
        </td>
        <td>
          <div>{team.name}</div>
          <div className="small text-muted text-nowrap">
            <Tooltip tooltip={team.contact?.name}>
              <span>{team.contact?.email}</span>
            </Tooltip>
            {team.user_count > 1 && (
              <>
                <span className="mx-1" style={{ opacity: 0.5 }}>
                  /
                </span>
                +{team.user_count - 1} other user{team.user_count != 2 ? "s" : ""}
              </>
            )}
          </div>
        </td>
        <td>
          <div className="text-nowrap">{team.plan.name}</div>
          <div className="small text-muted text-nowrap">
            {capitalize(team.plan.subscription_status?.replaceAll("_", " "))}
          </div>
        </td>
        <td>
          {lastLoginDate && (
            <span title={lastLoginDate.toLocaleString(DateTime.DATETIME_FULL)} className="text-nowrap">
              {lastLoginDate.toRelative()}
            </span>
          )}
        </td>
        <td>
          <div className="d-flex flex-wrap gap-2">
            {team.apps.map((app) => {
              const tooltip = (
                <div className="text-start">
                  <div className="very-small text-muted-tooltip">Framework</div>
                  <div>{app.framework}</div>
                  {app.envs.length > 0 && (
                    <>
                      <div className="very-small text-muted-tooltip mt-2">Environments</div>
                      {app.envs.map((env) => {
                        const lastSyncAt = env.last_sync_at ? DateTime.fromISO(env.last_sync_at) : undefined;
                        return (
                          <div key={env.id}>
                            <span
                              className={classNames("me-icon text-nowrap", {
                                "text-primary": env.online,
                                "text-danger": !env.online,
                              })}
                            >
                              <FontAwesomeIcon icon={faCircle} size="xs" />
                            </span>
                            <span>{env.name}</span>
                            {!env.online && !!lastSyncAt && (
                              <>
                                <span className="small ms-2 text-nowrap">({lastSyncAt?.toRelative()})</span>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              );
              return (
                <Tooltip key={app.id} tooltip={tooltip} placement="top">
                  <div
                    className="border rounded text-nowrap"
                    style={{
                      paddingLeft: "0.375rem",
                      paddingRight: "0.5rem",
                      paddingTop: "0.125rem",
                      paddingBottom: "0.125rem",
                    }}
                  >
                    <span className="me-icon">
                      {app.suspended ? (
                        <FontAwesomeIcon
                          icon={faPauseCircle}
                          className="text-danger"
                          style={{ position: "relative", top: 1, marginLeft: "0.125rem", marginRight: "0.25rem" }}
                        />
                      ) : (
                        <AppFrameworkIcon framework={app.framework} active={app.online} height={22} />
                      )}
                    </span>
                    <span className="small">{app.name}</span>
                  </div>
                </Tooltip>
              );
            })}
          </div>
        </td>
        <td style={{ width: 60 }} className="pe-2 align-middle">
          <div className="d-flex flex-row align-items-center justify-content-end">
            {joinedInStealth && <FontAwesomeIcon icon={faUserNinja} className="text-very-muted me-2" />}
            {team.plan_limits_status !== "ok" && (
              <Tooltip
                tooltip={
                  team.plan_limits_status === "grace" ? (
                    <>
                      In grace period
                      <br />
                      <span className="small">(ends {gracePeriodEndDate?.toRelative()})</span>
                    </>
                  ) : (
                    "Exceeding plan limits"
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className={classNames(
                    {
                      "text-very-muted": team.plan_limits_status === "grace",
                      "text-danger": team.plan_limits_status === "exceeded",
                    },
                    "me-2",
                  )}
                />
              </Tooltip>
            )}
          </div>
        </td>
        <TableCellDropdown>
          <Dropdown.Item as="button" onClick={() => setDetailsModalTeam(team)}>
            <FontAwesomeIcon icon={faEye} fixedWidth className="text-secondary" />
            Team details
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            onClick={() => {
              window.location.href = `mailto:${team.contact?.email}`;
            }}
          >
            <FontAwesomeIcon icon={faEnvelope} fixedWidth className="text-secondary" />
            Send email
          </Dropdown.Item>
          {!joined && !joinedInStealth && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item as="button" onClick={() => joinTeam(team.id)}>
                <FontAwesomeIcon icon={faUserNinja} fixedWidth className="text-secondary" />
                Join team in stealth
              </Dropdown.Item>
            </>
          )}
          {joinedInStealth && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item as="button" onClick={() => leaveTeam(team.id)}>
                <FontAwesomeIcon icon={faDoorOpen} fixedWidth className="text-secondary" />
                Leave team
              </Dropdown.Item>
            </>
          )}
        </TableCellDropdown>
      </tr>
    );
  });

  const searchHeader = (
    <TableCardSearchHeader search={search} setSearch={setSearch} placeholder="Search teams" paddingLeft={51} />
  );
  let tableFooter = undefined;
  if (query.isSuccess && searchResult && searchResult.length > 0) {
    const showingItemsFrom = itemsPerPage * (page - 1) + 1;
    const showingItemsTo = Math.min(itemsPerPage * page, searchResult.length);
    const numberOfPages = Math.ceil(searchResult.length / itemsPerPage);
    tableFooter = (
      <Stack direction="horizontal">
        <div className="small text-muted">
          Showing teams {showingItemsFrom.toLocaleString()}-{showingItemsTo.toLocaleString()} of{" "}
          {searchResult.length.toLocaleString()}
        </div>
        <div className="ms-auto">
          <Pagination page={page} numberOfPages={numberOfPages} setPage={setPage} />
        </div>
      </Stack>
    );
  }
  const filterDropdownItems = (
    <>
      <Dropdown.Item eventKey="0" active={!filter} onClick={() => setFilter(undefined)}>
        All teams
      </Dropdown.Item>
      <Dropdown.Item eventKey="1" active={filter === "active"} onClick={() => setFilter("active")}>
        Active teams
      </Dropdown.Item>
      <Dropdown.Item eventKey="1" active={filter === "paying"} onClick={() => setFilter("paying")}>
        Paying teams
      </Dropdown.Item>
    </>
  );
  const pageHeaderButtons = [
    <CustomDropdown
      key="filter-dropdown"
      icon="/icons/filter-regular.svg"
      title={filter == "paying" ? `Paying teams` : filter == "active" ? "Active teams" : "All teams"}
    >
      <Dropdown.Menu className="lh-base">{filterDropdownItems}</Dropdown.Menu>
    </CustomDropdown>,
    <RefreshButton key="refresh-button" onClick={refresh} loading={isLoading} />,
  ];
  const pageHeaderDropdown = (
    <Dropdown.Menu>
      <Dropdown.Item as="button" disabled={isLoading} onClick={refresh}>
        <FontAwesomeIcon icon={faRotateRight} fixedWidth />
        Refresh
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Filter teams</Dropdown.Header>
      {filterDropdownItems}
    </Dropdown.Menu>
  );

  return (
    <MainLayout>
      <AdminTeamDetailsModal team={detailsModalTeam} setTeam={setDetailsModalTeam} />
      <div className="AdminTeams">
        <PageHeader noDemoBadge buttons={pageHeaderButtons} dropdownMenu={pageHeaderDropdown}>
          <>Admin</>
          <>Teams</>
        </PageHeader>
        <TableCard
          responsive
          hover={searchResult && searchResult.length > 0}
          header={searchHeader}
          footer={tableFooter}
          className="align-top"
        >
          <thead>
            <tr>
              <th style={{ width: 44 }}></th>
              <th>Team</th>
              <th style={{ width: 150 }}>Plan</th>
              <th style={{ width: 170 }}>Last login</th>
              <th>Apps</th>
              <th></th>
              <th style={{ width: 40 }}></th>
            </tr>
          </thead>
          <tbody>
            {teamRows}
            {!teamRows && (
              <Placeholder as="tr" animation="glow">
                <td style={{ width: 44 }}></td>
                <td>
                  <div>
                    <Placeholder xs={5} />
                  </div>
                  <div className="small text-body-secondary">
                    <Placeholder xs={7} />
                  </div>
                </td>
                <td>
                  <Placeholder xs={8} />
                </td>
                <td>
                  <Placeholder xs={8} />
                </td>
                <td></td>
                <td style={{ width: 40 }}></td>
              </Placeholder>
            )}
            {query.isSuccess && query.data.length > 0 && search && searchResult && searchResult.length === 0 && (
              <tr>
                <td className="d-none d-lg-table-cell" style={{ width: 44 }}></td>
                <td colSpan={4} className="text-center py-6">
                  Your search didn't match any teams.
                </td>
                <td className="d-none d-lg-table-cell" style={{ width: 44 }}></td>
              </tr>
            )}
          </tbody>
        </TableCard>
      </div>
    </MainLayout>
  );
}

export default AdminTeams;
