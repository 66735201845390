import { faPenToSquare, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faGripLines, faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { Reorder } from "framer-motion";
import { useFeatureFlagEnabled } from "posthog-js/react";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { default as BootstrapForm } from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ListGroup from "react-bootstrap/ListGroup"; // eslint-disable-line @typescript-eslint/no-unused-vars
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ApiError, AppEnvItem } from "../backend";
import AppEnvBadge from "../components/AppEnvBadge";
import CollapsibleModalBody from "../components/CollapsibleModalBody";
import IconButton from "../components/IconButton";
import Tooltip from "../components/Tooltip";
import UpgradePlanAlert from "../components/UpgradePlanAlert";
import { useGlobal } from "../contexts/GlobalContext";
import { getBackendValidationErrorMessage } from "../utils/backend";
import { slugify } from "../utils/slugify";
import "./AppFormModal.css";

type AppEnvItemProps = {
  env: AppEnvItem | NewAppEnvItem;
  allowEdit?: boolean;
  onUpdate: (env: AppEnvItem | NewAppEnvItem) => void;
  onDelete: () => void;
  disabled?: boolean;
};

function AppEnvListGroupItem({ env, allowEdit = true, onUpdate, onDelete, disabled = false }: AppEnvItemProps) {
  const { isTeamAdmin, isDemo } = useGlobal();
  const [slug, setSlug] = useState(env.slug);
  const [livenessCheckEnabled, setLivenessCheckEnabled] = useState(env.liveness_check_enabled);
  const [edit, setEdit] = useState(!env.slug);
  const editInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (edit) {
      editInputRef.current?.focus();
    }
  }, [edit]);

  useEffect(() => {
    env.slug = slug;
    env.liveness_check_enabled = livenessCheckEnabled;
    onUpdate(env);
  }, [slug, livenessCheckEnabled]);

  const allowDelete = !env.online;
  const deleteButton = (
    <Tooltip
      condition={!allowDelete || (isDemo && !isTeamAdmin)}
      placement="left"
      tooltip={!allowDelete ? "Environments with active instances cannot be deleted" : "Not allowed in demo"}
    >
      <IconButton
        icon={faTrashCan}
        iconClassName="text-danger"
        title="Delete environment"
        disabled={!allowDelete || !isTeamAdmin || disabled}
        onClick={() => onDelete()}
      />
    </Tooltip>
  );
  return (
    <Container fluid>
      <Row className="py-1">
        <Col xs={4} className="d-flex align-items-center ps-0">
          <span className="grabbable">
            <FontAwesomeIcon icon={faGripLines} className="text-very-muted me-4" />
          </span>
          {!edit && (
            <span
              className={classNames("text-nowrap", { "cursor-pointer": !disabled && allowEdit })}
              onClick={() => !disabled && allowEdit && setEdit(true)}
              style={{ lineHeight: 1 }}
            >
              <AppEnvBadge env={{ name: env.slug, online: env.online }} />
              {!disabled && allowEdit && (
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  className="small text-very-muted ms-2 visible-hover"
                  style={{ position: "relative", top: 1 }}
                />
              )}
            </span>
          )}
          {edit && (
            <Formik
              initialValues={{ env: slug || "" }}
              validate={(values) => {
                const errors: { env?: string } = {};
                values.env = slugify(values.env);
                if (!values.env || values.env.trim() === "") {
                  errors.env = "Required";
                }
                return errors;
              }}
              onSubmit={async (values) => {
                setSlug(values.env);
                setEdit(false);
              }}
            >
              {({ errors, touched, handleSubmit }) => (
                <>
                  <Col xs={7} className="d-flex align-items-center px-0">
                    <Field name="env">
                      {({ field: { onBlur, ...restField } }: FieldProps<string>) => (
                        <BootstrapForm.Control
                          type="text"
                          size="sm"
                          placeholder="env"
                          maxLength={32}
                          isInvalid={!!errors.env && !!touched.env}
                          ref={editInputRef}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleSubmit();
                              e.preventDefault();
                            }
                          }}
                          onBlur={(e) => {
                            onBlur(e);
                            handleSubmit();
                          }}
                          {...restField}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={1} className="d-flex align-items-center pe-0">
                    <div className="w-100 text-end">
                      <IconButton
                        icon={faCheck}
                        iconClassName="text-primary"
                        className="ms-2"
                        title="Confirm"
                        onClick={() => handleSubmit()}
                      />
                    </div>
                  </Col>
                </>
              )}
            </Formik>
          )}
        </Col>
        <Col xs={6} className="d-flex align-items-center">
          <BootstrapForm.Check
            type="switch"
            id={`env-${slug}-liveness-check-enabled`}
            label="Uptime alerts"
            checked={livenessCheckEnabled}
            disabled={!isTeamAdmin || disabled}
            onChange={(e) => setLivenessCheckEnabled(e.target.checked)}
          />
          <Tooltip
            tooltip="If enabled, we'll alert you if we stop receiving data from this environment"
            placement="right"
          >
            <FontAwesomeIcon icon={faQuestionCircle} className="ms-2 text-body-tertiary d-none d-sm-inline" />
          </Tooltip>
        </Col>
        <Col xs={2} className="d-flex align-items-center pe-0">
          <div className="w-100 text-end">{deleteButton}</div>
        </Col>
      </Row>
    </Container>
  );
}

type NewAppEnvItem = {
  slug: string;
  liveness_check_enabled: boolean;
  online: boolean;
};

type AppFormValues = {
  name: string;
  framework: string;
  envs: AppEnvItem[] | NewAppEnvItem[];
  target_response_time_ms: number;
  sentry_org: string;
  sentry_project: string;
};

type AppFormModalProps = {
  appId?: number;
  setAppId: React.Dispatch<React.SetStateAction<number | undefined>>;
};

function AppFormModal({ appId, setAppId }: AppFormModalProps) {
  const { activeTeam, backendClient, teamPlan, refreshApps, refreshTeams, refreshTeamPlan, isTeamAdmin, isDemo } =
    useGlobal();
  const [initialFormValues, setInitialFormValues] = useState<AppFormValues>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const rustFlagEnabled = import.meta.env.DEV || useFeatureFlagEnabled("rust");
  const appLimitReached =
    !!teamPlan?.limits.apps.allowed && teamPlan.limits.apps.actual >= teamPlan.limits.apps.allowed;
  const disableForm = appId === 0 && appLimitReached;

  const queryParams = { appId: appId || 0 };
  const query = useQuery({
    queryKey: ["app", queryParams],
    queryFn: () => backendClient!.apps.getApp(queryParams),
    enabled: !!backendClient && !!appId,
    placeholderData: undefined,
  });

  useEffect(() => {
    if (appId === 0) {
      setInitialFormValues({
        name: "",
        framework: "",
        envs: [
          { slug: "prod", liveness_check_enabled: true, online: false },
          { slug: "dev", liveness_check_enabled: false, online: false },
        ],
        target_response_time_ms: 500,
        sentry_org: "",
        sentry_project: "",
      });
    } else if (appId && query.data) {
      setInitialFormValues({
        name: query.data.name,
        framework: query.data.framework,
        envs: query.data.envs.map((env) => ({
          slug: env.slug,
          liveness_check_enabled: env.liveness_check_enabled,
          online: env.online,
        })),
        target_response_time_ms: query.data.target_response_time_ms,
        sentry_org: query.data.sentry_org || "",
        sentry_project: query.data.sentry_project || "",
      });
    } else {
      setInitialFormValues(undefined);
    }
  }, [appId, query.data]);

  const validate = (values: AppFormValues) => {
    values.sentry_org = slugify(values.sentry_org);
    values.sentry_project = slugify(values.sentry_project);

    const errors: {
      name?: string;
      framework?: string;
      envs?: string;
      sentry_org?: string;
      sentry_project?: string;
    } = {};
    if (!values.name || values.name.trim() === "") {
      errors.name = "Required";
    } else if (values.name.trim().length < 2) {
      errors.name = "Name must be at least 2 characters";
    }
    if (!values.framework || values.framework === "") {
      errors.framework = "Required";
    }
    if (values.envs.length === 0) {
      errors.envs = "At least one environment required";
    }
    const envSlugs = new Set();
    if (values.envs.some((env) => envSlugs.size === envSlugs.add(env.slug).size)) {
      errors.envs = "Environment names must be unique";
    }
    if (values.sentry_org && !values.sentry_project) {
      errors.sentry_project = "Project name required";
    }
    if (!values.sentry_org && values.sentry_project) {
      errors.sentry_org = "Org slug required";
    }
    return errors;
  };

  const createOrUpdateApp = async (values: AppFormValues) => {
    if (backendClient && activeTeam) {
      if (appId) {
        const promise = backendClient.apps.updateApp({
          appId,
          requestBody: {
            name: values.name,
            framework: values.framework,
            envs: values.envs,
            target_response_time_ms: values.target_response_time_ms,
            sentry_org: values.sentry_org || null,
            sentry_project: values.sentry_project || null,
          },
        });
        toast.promise(promise, {
          pending: "Updating app...",
          success: "App updated!",
          error: "Failed to update app.",
        });
        await promise;
      } else {
        const promise = backendClient.apps.createApp({
          requestBody: {
            name: values.name,
            framework: values.framework,
            envs: values.envs,
            target_response_time_ms: values.target_response_time_ms,
            sentry_org: values.sentry_org || null,
            sentry_project: values.sentry_project || null,
            team_id: activeTeam.id,
          },
        });
        toast.promise(promise, {
          pending: "Creating app...",
          success: "App created!",
          error: "Failed to create app.",
        });
        const createdApp = await promise;
        return `/apps/${createdApp.slug}/setup`;
      }
    }
  };

  if (!initialFormValues) {
    return <></>;
  }

  return (
    <Formik
      initialValues={initialFormValues}
      validate={validate}
      onSubmit={async (values, { setFieldError, setSubmitting }) => {
        if (!isTeamAdmin) {
          return;
        }
        try {
          values.envs = values.envs.filter((env) => env.slug);
          const navigateTo = await createOrUpdateApp(values);
          if (appId) {
            queryClient.invalidateQueries({ queryKey: ["app", { appId }] });
            // Invalidate performance related queries in case the response time threshold has changed
            queryClient.invalidateQueries({ queryKey: ["performanceMetrics", { appId }] });
            queryClient.invalidateQueries({ queryKey: ["performanceEndpointsTable", { appId }] });
            queryClient.invalidateQueries({ queryKey: ["apdexScoreChart", { appId }] });
          } else {
            refreshTeams();
            refreshTeamPlan();
          }
          setAppId(undefined);
          refreshApps()?.then(() => {
            if (navigateTo) {
              navigate(navigateTo);
            }
          });
        } catch (e) {
          if (e instanceof ApiError) {
            if (e.status === 409) {
              setFieldError("name", "An app with this name already exists within your team.");
            } else if (e.status === 422) {
              setFieldError("name", getBackendValidationErrorMessage(e, ["body", "name"]));
            }
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue, resetForm }) => (
        <Modal
          className="AppFormModal"
          size="lg"
          show={appId !== undefined}
          onHide={() => setAppId(undefined)}
          onExited={() => resetForm()}
        >
          <Modal.Header closeButton>
            <Modal.Title>{appId ? "Edit app" : "Create app ✨"}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              {disableForm && appLimitReached && (
                <UpgradePlanAlert>
                  Your team has reached the maximum number of allowed apps in the {teamPlan?.name}.
                  <br />
                  Please upgrade your team's plan to create more apps and enjoy additional benefits!
                </UpgradePlanAlert>
              )}

              <BootstrapForm.Group controlId="formName">
                <BootstrapForm.Label>
                  Name <span className="text-danger">*</span>
                </BootstrapForm.Label>
                <Field name="name">
                  {({ field }: FieldProps<string>) => (
                    <BootstrapForm.Control
                      type="text"
                      placeholder="Name of your app"
                      maxLength={64}
                      autoComplete="off"
                      isInvalid={!!errors.name && !!touched.name}
                      disabled={disableForm}
                      {...field}
                    />
                  )}
                </Field>
                <BootstrapForm.Control.Feedback type="invalid">{errors.name}</BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>

              <BootstrapForm.Group controlId="formFramework" className="mt-4">
                <BootstrapForm.Label>
                  Framework <span className="text-danger">*</span>
                </BootstrapForm.Label>
                <Field name="framework">
                  {({ field }: FieldProps<string>) => (
                    <BootstrapForm.Select
                      isInvalid={!!errors.framework && !!touched.framework}
                      disabled={disableForm}
                      {...field}
                    >
                      <option value="" disabled>
                        Select framework...
                      </option>
                      <optgroup label="Python">
                        <option value="Django Ninja">Django Ninja</option>
                        <option value="Django REST Framework">Django REST Framework</option>
                        <option value="FastAPI">FastAPI</option>
                        <option value="Flask">Flask</option>
                        <option value="Litestar">Litestar</option>
                        <option value="Starlette">Starlette</option>
                      </optgroup>
                      <optgroup label="Node.js">
                        <option value="Express">Express</option>
                        <option value="Fastify">Fastify</option>
                        <option value="Hono">Hono</option>
                        <option value="Koa">Koa</option>
                        <option value="NestJS">NestJS</option>
                      </optgroup>
                      {rustFlagEnabled && (
                        <optgroup label="Rust">
                          <option value="Axum">Axum</option>
                        </optgroup>
                      )}
                    </BootstrapForm.Select>
                  )}
                </Field>
                <BootstrapForm.Control.Feedback type="invalid">{errors.framework}</BootstrapForm.Control.Feedback>
                {appId === 0 && (
                  <BootstrapForm.Text muted>
                    In the next step, we'll show you how to configure your application based on your selection here.
                  </BootstrapForm.Text>
                )}
              </BootstrapForm.Group>

              <BootstrapForm.Group controlId="formEnvs" className="mt-4">
                <BootstrapForm.Label>
                  Environments <span className="text-danger">*</span>
                </BootstrapForm.Label>
                <FieldArray name="envs">
                  {(arrayHelpers) => (
                    <>
                      <Reorder.Group
                        axis="y"
                        values={values.envs}
                        onReorder={(envs: AppEnvItem[] | NewAppEnvItem[]) => setFieldValue("envs", envs)}
                        as="div"
                        className={`list-group ${errors.envs ? "is-invalid" : ""}`}
                      >
                        {values.envs.map((env, index) => (
                          <Reorder.Item key={env.slug} value={env} as="div" className="list-group-item">
                            <AppEnvListGroupItem
                              env={env}
                              allowEdit={appId === 0 || !initialFormValues.envs.some((env2) => env.slug === env2.slug)}
                              onUpdate={() => arrayHelpers.replace(index, env)}
                              onDelete={() => arrayHelpers.remove(index)}
                              disabled={disableForm}
                            />
                          </Reorder.Item>
                        ))}
                      </Reorder.Group>
                      <div className="small mt-2">
                        <Button
                          variant="link"
                          disabled={disableForm || values.envs.length >= 5 || values.envs.some((env) => !env.slug)}
                          onClick={() => arrayHelpers.push({ slug: "", liveness_check_enabled: false })}
                        >
                          <FontAwesomeIcon icon={faPlus} className="me-icon" />
                          Add environment
                        </Button>
                      </div>
                    </>
                  )}
                </FieldArray>
                <BootstrapForm.Control.Feedback type="invalid">{errors.envs as string}</BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </Modal.Body>
            <CollapsibleModalBody title="Performance metrics" initialCollapsed={appId === 0}>
              <BootstrapForm.Group controlId="formApdex">
                <BootstrapForm.Label>Response time threshold</BootstrapForm.Label>
                <InputGroup>
                  <Field name="target_response_time_ms">
                    {({ field }: FieldProps<string>) => (
                      <BootstrapForm.Control
                        type="number"
                        min={10}
                        max={60000}
                        step={10}
                        isInvalid={!!errors.target_response_time_ms && !!touched.target_response_time_ms}
                        disabled={disableForm}
                        {...field}
                      />
                    )}
                  </Field>
                  <InputGroup.Text className="text-secondary">ms</InputGroup.Text>
                </InputGroup>
                <BootstrapForm.Text muted>
                  This is used to calculate Apdex scores. Response times below or equal to the configured threshold are
                  considered satisfactory. You can override the threshold for individual endpoints too.
                </BootstrapForm.Text>
              </BootstrapForm.Group>
            </CollapsibleModalBody>
            <CollapsibleModalBody title="Sentry integration" initialCollapsed={appId === 0}>
              <BootstrapForm.Group controlId="formSentry">
                <BootstrapForm.Label>Sentry project</BootstrapForm.Label>
                <InputGroup>
                  <InputGroup.Text className="text-secondary d-none d-lg-block">https://sentry.io/</InputGroup.Text>
                  <Field name="sentry_org">
                    {({ field }: FieldProps<string>) => (
                      <BootstrapForm.Control
                        type="text"
                        placeholder="org-slug"
                        maxLength={64}
                        isInvalid={!!errors.sentry_org && !!touched.sentry_org}
                        disabled={disableForm}
                        {...field}
                      />
                    )}
                  </Field>
                  <InputGroup.Text className="text-secondary">/</InputGroup.Text>
                  <Field name="sentry_project">
                    {({ field }: FieldProps<string>) => (
                      <BootstrapForm.Control
                        type="text"
                        placeholder="project-name"
                        maxLength={64}
                        isInvalid={!!errors.sentry_project && !!touched.sentry_project}
                        disabled={disableForm}
                        {...field}
                      />
                    )}
                  </Field>
                </InputGroup>
                <BootstrapForm.Control.Feedback
                  type="invalid"
                  className={
                    (!!errors.sentry_org && !!touched.sentry_org) ||
                    (!!errors.sentry_project && !!touched.sentry_project)
                      ? "d-block"
                      : undefined
                  }
                >
                  {errors.sentry_org || errors.sentry_project}
                </BootstrapForm.Control.Feedback>
                <BootstrapForm.Text muted>
                  Configuring this allows linking server errors captured in Apitally to the relevant issue in Sentry.
                </BootstrapForm.Text>
              </BootstrapForm.Group>
            </CollapsibleModalBody>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setAppId(undefined)}>
                Discard
              </Button>
              <Tooltip condition={isDemo && !isTeamAdmin} tooltip="Not allowed in demo" placement="bottom">
                <Button
                  type="submit"
                  onClick={() => handleSubmit()}
                  disabled={disableForm || isSubmitting || !isTeamAdmin}
                >
                  Submit
                </Button>
              </Tooltip>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default AppFormModal;
