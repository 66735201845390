import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { AppEnvItem, ListAppsResponseItem } from "../backend";
import useAppSlug from "../hooks/useAppSlug";
import { getInitialPeriod, isCustomPeriod, validatePeriod } from "../utils/period";

type FilterContextType = {
  app?: ListAppsResponseItem;
  appSlug?: string;
  period: string;
  setPeriod: (period: string) => void;
  resetPeriod: () => void;
  env?: AppEnvItem;
  setEnv: (env?: AppEnvItem) => void;
  consumerId?: number;
  setConsumerId: (consumerId?: number) => void;
  consumerGroupId?: number;
  setConsumerGroupId: (consumerGroupId?: number) => void;
  endpointId?: number;
  setEndpointId: (endpointId?: number) => void;
  endpointGroupId?: number;
  setEndpointGroupId: (endpointGroupId?: number) => void;
  method?: string;
  setMethod: (method?: string) => void;
  statusCode?: string;
  setStatusCode: (method?: string) => void;
  url?: string;
  setUrl: (url?: string) => void;
  minRequestSize?: number;
  maxRequestSize?: number;
  setRequestSizeRange: (minRequestSize?: number, maxRequestSize?: number) => void;
  minResponseSize?: number;
  maxResponseSize?: number;
  setResponseSizeRange: (minResponseSize?: number, maxResponseSize?: number) => void;
  minResponseTime?: number;
  maxResponseTime?: number;
  setResponseTimeRange: (minResponseTime?: number, maxResponseTime?: number) => void;
};

const FilterContext = React.createContext<FilterContextType | undefined>(undefined);

export function useFilters() {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error("useFilters must be used within a FilterContextProvider");
  }
  return context;
}

type FilterContextProviderProps = {
  disableConsumer?: boolean;
  disableConsumerGroup?: boolean;
  disableEndpointGroup?: boolean;
  children: React.ReactNode;
};

export function FilterContextProvider({
  disableConsumer = false,
  disableConsumerGroup = false,
  disableEndpointGroup = false,
  children,
}: FilterContextProviderProps) {
  const { app, appSlug } = useAppSlug();
  const [searchParams, setSearchParams] = useSearchParams();

  const setSearchParam = (key: string, value?: string) => {
    setSearchParams(
      (searchParams) => {
        if (value) {
          searchParams.set(key, value);
        } else {
          searchParams.delete(key);
        }
        return searchParams;
      },
      { replace: true },
    );
  };
  const setSessionStorage = (key: string, value?: string) => {
    if (value) {
      sessionStorage.setItem(key, value);
    } else {
      sessionStorage.removeItem(key);
    }
  };

  // Period - sessionStorage
  const storePeriod = (period: string) => {
    if (isCustomPeriod(period)) {
      sessionStorage.setItem("customPeriod", period);
    } else {
      sessionStorage.setItem("period", period);
      sessionStorage.removeItem("customPeriod");
    }
  };
  const setPeriod = (period: string) => {
    setSearchParam("period", period);
    storePeriod(period);
  };
  const periodSearchParam = searchParams.get("period");
  const period = periodSearchParam && validatePeriod(periodSearchParam) ? periodSearchParam : getInitialPeriod();
  const resetPeriod = () => {
    if (isCustomPeriod(period)) {
      setPeriod(getInitialPeriod({ allowCustom: false }));
    }
  };

  useEffect(() => {
    storePeriod(period);
  }, [period]);

  // App env - sessionStorage
  const envSlugSearchParam = searchParams.get("env");
  const envSlugSessionStorageKey = `env:${app?.id}`;
  const envSlugSessionStorage = sessionStorage.getItem(envSlugSessionStorageKey);
  const envSlug = envSlugSearchParam || envSlugSessionStorage || undefined;
  const env = app?.envs.find((env) => env.slug === envSlug);
  const setEnv = (env?: AppEnvItem) => {
    setSearchParam("env", env?.slug);
    setSessionStorage(envSlugSessionStorageKey, env?.active ? env.slug : undefined);
  };

  useEffect(() => {
    setSessionStorage(envSlugSessionStorageKey, env?.active ? env.slug : undefined);
  }, [env]);

  // Consumer - sessionStorage
  const consumerSearchParam = parseInt(searchParams.get("consumer") || "0");
  const consumerSessionStorageKey = `consumer:${app?.id}`;
  const consumerSessionStorage = parseInt(sessionStorage.getItem(consumerSessionStorageKey) || "0");
  const consumerId = !disableConsumer ? consumerSearchParam || consumerSessionStorage || undefined : undefined;
  const setConsumerId = (consumerId?: number) => {
    setSearchParam("consumer", consumerId?.toString());
    setSessionStorage(consumerSessionStorageKey, consumerId?.toString());
  };

  useEffect(() => {
    if (!disableConsumer) {
      setSessionStorage(consumerSessionStorageKey, consumerId?.toString());
    }
  }, [consumerId]);

  // Consumer group - sessionStorage
  const consumerGroupSearchParam = parseInt(searchParams.get("consumer_group") || "0");
  const consumerGroupSessionStorageKey = `consumerGroup:${app?.id}`;
  const consumerGroupSessionStorage = parseInt(sessionStorage.getItem(consumerGroupSessionStorageKey) || "0");
  const consumerGroupId = !disableConsumerGroup
    ? consumerGroupSearchParam || consumerGroupSessionStorage || undefined
    : undefined;
  const setConsumerGroupId = (consumerGroupId?: number) => {
    setSearchParam("consumer_group", consumerGroupId?.toString());
    setSessionStorage(consumerGroupSessionStorageKey, consumerGroupId?.toString());
  };

  useEffect(() => {
    if (!disableConsumerGroup) {
      setSessionStorage(consumerGroupSessionStorageKey, consumerGroupId?.toString());
    }
  }, [consumerGroupId]);

  // Endpoint
  const endpointId = parseInt(searchParams.get("endpoint") || "0") || undefined;
  const setEndpointId = (endpointId?: number) => {
    setSearchParam("endpoint", endpointId?.toString());
  };

  // Endpoint group - sessionStorage
  const endpointGroupSearchParam = parseInt(searchParams.get("endpoint_group") || "0");
  const endpointGroupSessionStorageKey = `endpointGroup:${app?.id}`;
  const endpointGroupSessionStorage = parseInt(sessionStorage.getItem(endpointGroupSessionStorageKey) || "0");
  const endpointGroupId = !disableEndpointGroup
    ? endpointGroupSearchParam || endpointGroupSessionStorage || undefined
    : undefined;
  const setEndpointGroupId = (endpointGroupId?: number) => {
    setSearchParam("endpoint_group", endpointGroupId?.toString());
    setSessionStorage(endpointGroupSessionStorageKey, endpointGroupId?.toString());
  };

  useEffect(() => {
    if (!disableEndpointGroup) {
      setSessionStorage(endpointGroupSessionStorageKey, endpointGroupId?.toString());
    }
  }, [endpointGroupId]);

  // Method
  const method = searchParams.get("method") || undefined;
  const setMethod = (method?: string) => {
    setSearchParam("method", method);
  };

  // Status code
  const statusCode = searchParams.get("status_code") || undefined;
  const setStatusCode = (statusCode?: string) => {
    setSearchParam("status_code", statusCode);
  };

  // URL
  const url = searchParams.get("url") || undefined;
  const setUrl = (url?: string) => {
    setSearchParam("url", url);
  };

  // Request size
  const minRequestSize = parseFloat(searchParams.get("min_request_size") || "0") || undefined;
  const maxRequestSize = parseFloat(searchParams.get("max_request_size") || "0") || undefined;
  const setRequestSizeRange = (minRequestSize?: number, maxRequestSize?: number) => {
    setSearchParam("min_request_size", minRequestSize?.toString());
    setSearchParam("max_request_size", maxRequestSize?.toString());
  };

  // Response size
  const minResponseSize = parseFloat(searchParams.get("min_response_size") || "0") || undefined;
  const maxResponseSize = parseFloat(searchParams.get("max_response_size") || "0") || undefined;
  const setResponseSizeRange = (minResponseSize?: number, maxResponseSize?: number) => {
    setSearchParam("min_response_size", minResponseSize?.toString());
    setSearchParam("max_response_size", maxResponseSize?.toString());
  };

  // Response time
  const minResponseTime = parseInt(searchParams.get("min_response_time") || "0") || undefined;
  const maxResponseTime = parseInt(searchParams.get("max_response_time") || "0") || undefined;
  const setResponseTimeRange = (minResponseTime?: number, maxResponseTime?: number) => {
    setSearchParam("min_response_time", minResponseTime?.toString());
    setSearchParam("max_response_time", maxResponseTime?.toString());
  };

  useEffect(() => {
    if (app) {
      // Ensure query params are in sync with app state when page loads or app changes
      setPeriod(period);
      setEnv(env);
      if (!disableConsumer) {
        setConsumerId(consumerId);
      }
      if (!disableConsumerGroup) {
        setConsumerGroupId(consumerGroupId);
      }
      if (!disableEndpointGroup) {
        setEndpointGroupId(endpointGroupId);
      }
    }
  }, [app]);

  return (
    <FilterContext.Provider
      value={{
        app,
        appSlug,
        period,
        setPeriod,
        resetPeriod,
        env,
        setEnv,
        consumerId,
        setConsumerId,
        consumerGroupId,
        setConsumerGroupId,
        endpointId,
        setEndpointId,
        endpointGroupId,
        setEndpointGroupId,
        method,
        setMethod,
        statusCode,
        setStatusCode,
        url,
        setUrl,
        minRequestSize,
        maxRequestSize,
        setRequestSizeRange,
        minResponseSize,
        maxResponseSize,
        setResponseSizeRange,
        minResponseTime,
        maxResponseTime,
        setResponseTimeRange,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}

export default FilterContextProvider;
