import { Field, FieldProps, Form, Formik } from "formik";
import React from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import BootstrapForm from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

import { useGlobal } from "../contexts/GlobalContext";

type TeamDeleteModalProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

interface TeamDeleteFormValues {
  name: string;
}

function TeamDeleteModal({ show, setShow }: TeamDeleteModalProps) {
  const { backendClient, teams, apps, activeTeam, teamPlan, setActiveTeam, refreshTeams } = useGlobal();
  const teamHasActiveSubscription =
    teamPlan?.subscription && !!teamPlan.subscription.payment_platform && teamPlan.subscription.status !== "canceled";
  const teamHasApps = apps && apps.length > 0;
  const isOnlyTeam = teams && teams.length === 1;

  const validate = (values: TeamDeleteFormValues) => {
    const errors: Partial<TeamDeleteFormValues> = {};
    if (values.name.trim() !== activeTeam?.name) {
      errors.name = "Name does not match";
    }
    return errors;
  };

  const deleteTeam = async () => {
    if (backendClient && activeTeam) {
      const promise = backendClient.teams.deleteTeam({ teamId: activeTeam.id });
      toast.promise(promise, {
        pending: "Deleting team...",
        success: "Team deleted!",
        error: "Failed to delete team.",
      });
      await promise;
    }
  };

  return (
    <Formik
      initialValues={{ name: "" }}
      validate={validate}
      onSubmit={async (values, { setSubmitting }) => {
        await deleteTeam();
        setShow(false);
        setSubmitting(false);
        const otherTeam = teams?.find((team) => team.id !== activeTeam?.id);
        setActiveTeam(otherTeam);
        refreshTeams();
      }}
    >
      {({ handleSubmit, isSubmitting, errors, touched, resetForm }) => (
        <Modal show={show} onHide={() => setShow(false)} onExited={() => resetForm()}>
          <Modal.Header closeButton>
            <Modal.Title>Delete team</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!teamHasActiveSubscription && !teamHasApps && !isOnlyTeam && (
              <>
                <Alert variant="danger">
                  Are you sure you want to delete the team <strong>{activeTeam?.name}</strong>? This action cannot be
                  undone.
                </Alert>
                <Form onSubmit={handleSubmit}>
                  <BootstrapForm.Group controlId="formName">
                    <BootstrapForm.Label className="fw-normal">
                      Enter the team name <strong>{activeTeam?.name}</strong> to confirm
                    </BootstrapForm.Label>
                    <Field name="name">
                      {({ field }: FieldProps<string>) => (
                        <BootstrapForm.Control
                          type="text"
                          placeholder={activeTeam?.name}
                          autoComplete="off"
                          isInvalid={!!errors.name && !!touched.name}
                          {...field}
                        />
                      )}
                    </Field>
                    <BootstrapForm.Control.Feedback type="invalid">{errors.name}</BootstrapForm.Control.Feedback>
                  </BootstrapForm.Group>
                </Form>
              </>
            )}
            {teamHasActiveSubscription && (
              <Alert variant="danger">
                You cannot delete the team <strong>{activeTeam?.name}</strong> because it has an active subscription.
                <br />
                Cancel the team's subscription first.
              </Alert>
            )}
            {!teamHasActiveSubscription && teamHasApps && (
              <Alert variant="danger">
                You cannot delete the team <strong>{activeTeam?.name}</strong> because it has apps.
                <br />
                Delete all apps associated with this team first.
              </Alert>
            )}
            {!teamHasActiveSubscription && !teamHasApps && isOnlyTeam && (
              <Alert variant="danger">
                You cannot delete the team <strong>{activeTeam?.name}</strong> because it's your only team and you must
                be a member of at least one team.
                <br />
                Create another team first.
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => handleSubmit()}
              disabled={teamHasApps || isOnlyTeam || isSubmitting}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
}

export default TeamDeleteModal;
