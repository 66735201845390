import { faCheck, faCog, faEllipsisVertical, faFlask, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Field, FieldProps, Form, Formik } from "formik";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import BootstrapForm from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ApiError } from "../backend";
import UpgradePlanAlert from "../components/UpgradePlanAlert";
import { useGlobal } from "../contexts/GlobalContext";
import MicrosoftTeamsLogo from "../static/microsoft-teams-logo.svg?react";
import SentryLogo from "../static/sentry-logo.svg?react";
import SlackLogo from "../static/slack-logo.svg?react";

function isValidHttpsUrl(value: string) {
  let url;
  try {
    url = new URL(value);
  } catch (e) {
    return false;
  }
  return url.protocol === "https:";
}

type MicrosoftTeamsConfigurationFormProps = {
  webhookUrl?: string;
  setShow: (show: boolean) => void;
};

function MicrosoftTeamsConfigurationForm({ webhookUrl, setShow }: MicrosoftTeamsConfigurationFormProps) {
  const { activeTeam, backendClient } = useGlobal();
  const queryClient = useQueryClient();

  const validate = async (values: { webhook_url: string }) => {
    const errors: Partial<{ webhook_url: string }> = {};
    if (!values.webhook_url || values.webhook_url.trim() === "") {
      errors.webhook_url = "Required";
    } else if (!isValidHttpsUrl(values.webhook_url)) {
      errors.webhook_url = "Invalid URL";
    } else if (!values.webhook_url.includes(".webhook.office.com/")) {
      errors.webhook_url = "Invalid URL";
    }
    return errors;
  };

  return (
    <Formik
      initialValues={{ webhook_url: webhookUrl || "" }}
      validate={validate}
      onSubmit={async (values, { setFieldError, setSubmitting }) => {
        try {
          const promise = backendClient!.teams.configureMicrosoftTeamsIntegration({
            teamId: activeTeam!.id,
            requestBody: { webhook_url: values.webhook_url },
          });
          toast.promise(promise, {
            pending: "Configuring Microsoft Teams integration...",
            success: "Microsoft Teams integration configured!",
            error: "Failed to configure Microsoft Teams integration.",
          });
          await promise;
          queryClient.invalidateQueries({ queryKey: ["teamIntegrations", { teamId: activeTeam!.id }] });
          setShow(false);
        } catch (e) {
          if (e instanceof ApiError && e.status === 422) {
            setFieldError("webhook_url", "Invalid URL");
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, errors, touched }) => (
        <Form onSubmit={handleSubmit}>
          <div className="small text-muted mb-4">
            <p className="mb-2">Follow the steps below to create an incoming webhook for your channel:</p>
            <ol className="mb-2 ps-4">
              <li>
                Right-click on the channel and select <i>Manage channel</i>.
              </li>
              <li>
                Navigate to the <i>Settings</i> tab, expand <i>Connectors</i> and click <i>Edit</i>.
              </li>
              <li>
                Search for "Incoming Webhook" and click <i>Configure</i>.
              </li>
              <li>
                Provide "Apitally" as the name and click <i>Create</i>.
              </li>
              <li>Copy the URL and paste it below.</li>
            </ol>
          </div>
          <BootstrapForm.Group controlId="formWebhookUrl">
            <Field name="webhook_url">
              {({ field }: FieldProps<string>) => (
                <BootstrapForm.Control
                  type="text"
                  placeholder="https://your-team.webhook.office.com/webhookb2/..."
                  maxLength={512}
                  autoComplete="off"
                  isInvalid={!!errors.webhook_url && !!touched.webhook_url}
                  {...field}
                />
              )}
            </Field>
            <BootstrapForm.Control.Feedback type="invalid">{errors.webhook_url}</BootstrapForm.Control.Feedback>
          </BootstrapForm.Group>
          <Stack direction="horizontal" gap={2} className="mt-3">
            <Button variant="primary" onClick={() => handleSubmit()} disabled={isSubmitting}>
              Save
            </Button>
            <Button variant="secondary" onClick={() => setShow(false)} disabled={isSubmitting}>
              Cancel
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

type TeamIntegrationItemProps = {
  active?: boolean;
  logo: React.ElementType;
  title: string;
  button?: React.ReactNode;
  children?: React.ReactNode;
};

function TeamIntegrationItem({ active = false, logo: Logo, title, button, children }: TeamIntegrationItemProps) {
  const logoProps = {
    width: "auto",
    height: 24,
    style: { filter: !active ? "grayscale(1) opacity(0.6)" : undefined },
  };

  return (
    <ListGroup.Item className="py-4">
      <div className="d-flex flex-row">
        <div style={{ width: 32 }} className="me-4">
          <Logo {...logoProps} />
        </div>
        <div className="flex-grow-1">
          <div className="fw-bold">{title}</div>
          <div className="content">{children}</div>
        </div>
        {button && <div className="ms-auto">{button}</div>}
      </div>
    </ListGroup.Item>
  );
}

type TeamIntegrationsModalProps = {
  show: boolean;
  setShow: (show: boolean) => void;
};

function TeamIntegrationsModal({ show, setShow }: TeamIntegrationsModalProps) {
  const { activeTeam, apps, backendClient, teamPlan, isTeamAdmin } = useGlobal();
  const [showMicrosoftTeamsConfiguration, setShowMicrosoftTeamsConfiguration] = useState(false);
  const navigate = useNavigate();
  const disabled = !teamPlan?.features.chat_integration || !isTeamAdmin;

  const queryParams = { teamId: activeTeam?.id || 0 };
  const query = useQuery({
    queryKey: ["teamIntegrations", queryParams],
    queryFn: () => backendClient!.teams.getTeamIntegrations(queryParams),
    enabled: !!backendClient && !!activeTeam && show,
    staleTime: 0,
  });

  const configureSlackIntegration = () => {
    const params = new URLSearchParams({
      scope: "incoming-webhook",
      client_id: import.meta.env.VITE_SLACK_APP_CLIENT_ID,
      redirect_uri: import.meta.env.VITE_SLACK_APP_REDIRECT_URL,
      state: `team_id=${activeTeam?.id}`,
    });
    const url = `https://slack.com/oauth/v2/authorize?${params.toString()}`;
    window.location.href = url;
  };

  const testSlackIntegration = async () => {
    await backendClient!.teams.testSlackIntegration({ teamId: activeTeam!.id });
  };

  const testMicrosoftTeamsIntegration = async () => {
    await backendClient!.teams.testMicrosoftTeamsIntegration({ teamId: activeTeam!.id });
  };

  const deleteSlackIntegration = async () => {
    await backendClient!.teams.deleteSlackIntegration({ teamId: activeTeam!.id });
    query.refetch();
  };

  const deleteMicrosoftTeamsIntegration = async () => {
    await backendClient!.teams.deleteMicrosoftTeamsIntegration({ teamId: activeTeam!.id });
    query.refetch();
  };

  return (
    <Modal size="lg" show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Configure integrations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!teamPlan?.features.chat_integration && (
          <UpgradePlanAlert>
            Slack and Microsoft Teams integrations are not included in the {teamPlan?.name}.
            <br />
            Please upgrade your team's plan to use them and enjoy additional benefits!
          </UpgradePlanAlert>
        )}
        <p className="small text-muted mb-4">
          Manage integrations between Apitally and third-party services for your team <b>{activeTeam?.name}</b>.
        </p>
        <ListGroup>
          <TeamIntegrationItem
            active={query.isSuccess && !!query.data.slack}
            logo={SlackLogo}
            title="Slack"
            button={
              query.isSuccess && !query.data.slack ? (
                <Button variant="primary" onClick={configureSlackIntegration} disabled={disabled}>
                  Configure
                </Button>
              ) : (
                <Dropdown align="end" className="no-caret">
                  <Dropdown.Toggle variant="outline-light">
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={configureSlackIntegration} disabled={disabled}>
                      <FontAwesomeIcon icon={faCog} fixedWidth className="text-secondary" /> Re-configure
                    </Dropdown.Item>
                    <Dropdown.Item onClick={testSlackIntegration} disabled={disabled}>
                      <FontAwesomeIcon icon={faFlask} fixedWidth className="text-secondary" /> Send test message
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={deleteSlackIntegration} disabled={disabled} className="text-danger">
                      <FontAwesomeIcon icon={faXmark} fixedWidth />
                      Remove
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )
            }
          >
            <p className="mb-2">Receive alert notifications in a Slack channel.</p>
            {query.isSuccess && !query.data.slack && <p className="small text-muted mb-0">Not configured yet.</p>}
            {query.isSuccess && query.data.slack && (
              <p className="small text-muted mb-0">
                <span className="text-primary">
                  <FontAwesomeIcon icon={faCheck} className="me-icon" />
                  Configured
                </span>
                <span className="mx-1" style={{ opacity: 0.5 }}>
                  /
                </span>
                <span>{query.data.slack.team}</span>
                <span className="mx-1" style={{ opacity: 0.5 }}>
                  /
                </span>
                <span>{query.data.slack.channel}</span>
              </p>
            )}
          </TeamIntegrationItem>
          <TeamIntegrationItem
            active={query.isSuccess && !!query.data.microsoft_teams}
            logo={MicrosoftTeamsLogo}
            title="Microsoft Teams"
            button={
              !showMicrosoftTeamsConfiguration ? (
                query.isSuccess && !query.data.microsoft_teams ? (
                  <Button
                    variant="primary"
                    onClick={() => setShowMicrosoftTeamsConfiguration(true)}
                    disabled={disabled}
                  >
                    Configure
                  </Button>
                ) : (
                  <Dropdown align="end" className="no-caret">
                    <Dropdown.Toggle variant="outline-light">
                      <FontAwesomeIcon icon={faEllipsisVertical} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setShowMicrosoftTeamsConfiguration(true)} disabled={disabled}>
                        <FontAwesomeIcon icon={faCog} fixedWidth className="text-secondary" /> Re-configure
                      </Dropdown.Item>
                      <Dropdown.Item onClick={testMicrosoftTeamsIntegration} disabled={disabled}>
                        <FontAwesomeIcon icon={faFlask} fixedWidth className="text-secondary" /> Send test message
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={deleteMicrosoftTeamsIntegration}
                        disabled={disabled}
                        className="text-danger"
                      >
                        <FontAwesomeIcon icon={faXmark} fixedWidth />
                        Remove
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )
              ) : undefined
            }
          >
            <p className="mb-2">Receive alert notifications in a Microsoft Teams channel.</p>
            {showMicrosoftTeamsConfiguration ? (
              <MicrosoftTeamsConfigurationForm
                webhookUrl={query.data?.microsoft_teams?.webhook_url}
                setShow={setShowMicrosoftTeamsConfiguration}
              />
            ) : (
              <>
                {query.isSuccess && !query.data.microsoft_teams && (
                  <p className="small text-muted mb-0">Not configured yet.</p>
                )}
                {query.isSuccess && query.data.microsoft_teams && (
                  <p className="small text-muted mb-0">
                    <span className="text-primary">
                      <FontAwesomeIcon icon={faCheck} className="me-icon" />
                      Configured
                    </span>
                  </p>
                )}
              </>
            )}
          </TeamIntegrationItem>
          <TeamIntegrationItem
            active={query.isSuccess && !!query.data.sentry}
            logo={SentryLogo}
            title="Sentry"
            button={
              <Dropdown align="end" className="no-caret">
                <Dropdown.Toggle variant="outline-light">
                  <FontAwesomeIcon icon={faEllipsisVertical} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {apps?.map((app) => (
                    <Dropdown.Item key={app.id} onClick={() => navigate(`/apps?editApp=${app.id}`)} disabled={disabled}>
                      <FontAwesomeIcon icon={faCog} fixedWidth className="text-secondary" /> Configure for {app.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            }
          >
            <p className="mb-2">
              Link server errors captured in Apitally with their respective issues created in Sentry.
            </p>
            {query.isSuccess && !query.data.sentry && <p className="small text-muted mb-0">Not configured yet.</p>}
            {query.isSuccess && query.data.sentry && (
              <p className="small text-muted mb-0">
                <span className="text-primary">
                  <FontAwesomeIcon icon={faCheck} className="me-icon" />
                  Configured
                </span>
                {apps && apps.length > query.data.sentry.configured_app_ids.length && (
                  <>
                    <span className="mx-1" style={{ opacity: 0.5 }}>
                      /
                    </span>
                    <span>
                      {query.data.sentry.configured_app_ids.length} of {apps?.length} apps
                    </span>
                  </>
                )}
              </p>
            )}
          </TeamIntegrationItem>
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TeamIntegrationsModal;
