import { useAuth0 } from "@auth0/auth0-react";
import { faSlack, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { Field, FieldProps, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import BootstrapForm from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

import { useSearchParams } from "react-router-dom";
import "./Onboarding.scss";
import { ApiError, ListPlansResponseItem } from "./backend";
import SteppedProgressBar from "./components/SteppedProgressBar";
import { useGlobal } from "./contexts/GlobalContext";
import { PlanFeatureItems, PlanLimitsItems } from "./modals/PlansModal";
import LogoIcon from "./static/icon.svg?react";

type OnboardingStep1Props = {
  skipTeamCreation: boolean;
  onComplete: () => void;
};

interface OnboardingStep1FormValues {
  user_name: string;
  team_name: string;
}

function OnboardingStep1({ skipTeamCreation, onComplete }: OnboardingStep1Props) {
  const { user, getAccessTokenSilently, logout } = useAuth0();
  const { backendClient, activeTeam, refreshTeams } = useGlobal();

  const validate = async (values: OnboardingStep1FormValues) => {
    const errors: Partial<OnboardingStep1FormValues> = {};
    if (!values.user_name || values.user_name.trim() === "") {
      errors.user_name = "Required";
    }
    if (!skipTeamCreation && (!values.team_name || values.team_name.trim() === "")) {
      errors.team_name = "Required";
    }
    return errors;
  };

  const onboardUser = async (values: OnboardingStep1FormValues) => {
    if (backendClient) {
      const promise = backendClient.users.onboardUser({
        requestBody: {
          user_name: values.user_name,
          team_name: !skipTeamCreation ? values.team_name : undefined,
        },
      });
      toast.promise(promise, {
        error: "Failed to set up account.",
      });
      await promise;
    }
  };

  return (
    <Formik
      initialValues={
        user && user.name && user.name !== user.email
          ? {
              user_name: user.name,
              team_name: !skipTeamCreation ? activeTeam?.name || `${user.name}'s Team` : "",
            }
          : { user_name: "", team_name: !skipTeamCreation ? activeTeam?.name || "" : "" }
      }
      validate={validate}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        try {
          await onboardUser(values);
          refreshTeams();
          if (user) {
            user.name = values.user_name;
          }
          getAccessTokenSilently({
            cacheMode: "off",
          });
          onComplete();
        } catch (e) {
          if (e instanceof ApiError) {
            const detail = (e.body as any)?.detail;
            if (e.status === 422 && Array.isArray(detail)) {
              if (detail.some((error: any) => error.loc[0] === "body" && error.loc[1] === "user_name")) {
                setFieldError("user_name", "Invalid user name");
              }
              if (detail.some((error: any) => error.loc[0] === "body" && error.loc[1] === "team_name")) {
                setFieldError("team_name", "Invalid team name");
              }
            } else if (e.status === 409) {
              setFieldError("team_name", "A team with this name already exists.");
            }
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, setFieldValue, isSubmitting, values, errors, touched }) => (
        <>
          <Modal.Body>
            <div className="small text-muted mb-4">
              We just need to grab a couple of details to set up your account.
            </div>
            <Form onSubmit={handleSubmit}>
              <BootstrapForm.Group controlId="formEmail">
                <BootstrapForm.Label>Your email address</BootstrapForm.Label>
                <BootstrapForm.Control type="email" maxLength={64} value={user?.email} readOnly className="bg-light" />
                {!user?.email_verified && (
                  <BootstrapForm.Text muted>
                    We've just sent you an email to verify this email address.
                  </BootstrapForm.Text>
                )}
                {user?.email_verified && user?.sub?.startsWith("github|") && (
                  <BootstrapForm.Text muted>Your email address is already verified by GitHub.</BootstrapForm.Text>
                )}
                {user?.email_verified && user?.sub?.startsWith("google-oauth2|") && (
                  <BootstrapForm.Text muted>Your email address is already verified by Google.</BootstrapForm.Text>
                )}
              </BootstrapForm.Group>

              <BootstrapForm.Group controlId="formUserName" className="mt-4">
                <BootstrapForm.Label>
                  Your full name <span className="text-danger">*</span>
                </BootstrapForm.Label>
                <Field name="user_name">
                  {({ field: { onChange, ...field } }: FieldProps<string>) => (
                    <BootstrapForm.Control
                      type="text"
                      placeholder="John Doe? Is that you?"
                      maxLength={64}
                      isInvalid={!!errors.user_name && !!touched.user_name}
                      autoFocus
                      onChange={(e) => {
                        onChange(e);
                        if (!values.team_name.trim() || values.team_name.trim().endsWith("'s Team")) {
                          setFieldValue("team_name", e.target.value.trim() ? `${e.target.value.trim()}'s Team` : "");
                        }
                      }}
                      {...field}
                    />
                  )}
                </Field>
                <BootstrapForm.Control.Feedback type="invalid">{errors.user_name}</BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>

              <BootstrapForm.Group controlId="formTeamName" className="mt-4" hidden={skipTeamCreation}>
                <BootstrapForm.Label>
                  Team name <span className="text-danger">*</span>
                </BootstrapForm.Label>
                <Field name="team_name">
                  {({ field }: FieldProps<string>) => (
                    <BootstrapForm.Control
                      type="text"
                      placeholder="John Doe's Team? Or something else?"
                      maxLength={64}
                      isInvalid={!!errors.team_name && !!touched.team_name}
                      {...field}
                    />
                  )}
                </Field>
                <BootstrapForm.Control.Feedback type="invalid">{errors.team_name}</BootstrapForm.Control.Feedback>
                <BootstrapForm.Text muted>The team name must be unique. You can change it anytime.</BootstrapForm.Text>
              </BootstrapForm.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button variant="outline-light" onClick={() => logout()} disabled={isSubmitting}>
              Back to login
            </Button>
            <Button onClick={() => handleSubmit()} disabled={isSubmitting}>
              Continue
            </Button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
}

type OnboardingStep2Props = {
  onComplete: () => void;
  onBack: () => void;
  selectedPlan?: ListPlansResponseItem;
  setSelectedPlan: (plan: ListPlansResponseItem | undefined) => void;
};

function OnboardingStep2({ onComplete, onBack, selectedPlan, setSelectedPlan }: OnboardingStep2Props) {
  const { backendClient } = useGlobal();
  const [searchParams] = useSearchParams();

  const plansQuery = useQuery({
    queryKey: ["plans"],
    queryFn: () => backendClient!.plans.listPlans(),
    enabled: !!backendClient,
    staleTime: 60 * 60 * 1000,
  });

  const formatPrice = (price: number | string) => {
    return Number(price).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });
  };

  return (
    <Formik
      initialValues={{ plan: selectedPlan?.slug || searchParams.get("plan") || "free-plan" }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setSelectedPlan(plansQuery.data?.find((plan) => plan.slug === values.plan));
          onComplete();
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, values, errors, touched }) => {
        const selectedPlan2 = plansQuery.data?.find((plan) => plan.slug === values.plan);

        return (
          <>
            <Modal.Body>
              <Form onSubmit={handleSubmit}>
                <BootstrapForm.Group controlId="formPlan">
                  <BootstrapForm.Label>
                    Choose the right plan for you <span className="text-danger">*</span>
                  </BootstrapForm.Label>
                  <Field name="plan">
                    {({ field }: FieldProps<string>) => (
                      <BootstrapForm.Select isInvalid={!!errors.plan && !!touched.plan} {...field}>
                        {plansQuery.data?.map((plan) => (
                          <option key={plan.slug} value={plan.slug}>
                            {plan.name}
                            {Number(plan.price_per_month) > 0 && ` (${formatPrice(plan.price_per_month)} per month)`}
                          </option>
                        ))}
                      </BootstrapForm.Select>
                    )}
                  </Field>
                </BootstrapForm.Group>

                {selectedPlan2 && (
                  <div className="small rounded border mt-2">
                    <ul className="features">
                      <PlanLimitsItems plan={selectedPlan2} />
                      <PlanFeatureItems plan={selectedPlan2} />
                    </ul>
                  </div>
                )}
                <BootstrapForm.Text muted>You can upgrade or cancel your subscription at any time.</BootstrapForm.Text>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => onBack()}>
                Back
              </Button>
              <Button onClick={() => handleSubmit()} disabled={isSubmitting}>
                Continue
              </Button>
            </Modal.Footer>
          </>
        );
      }}
    </Formik>
  );
}

type OnboardingStep3Props = {
  onBack: () => void;
  selectedPlan?: ListPlansResponseItem;
};

interface OnboardingStep3FormValues {
  source: string;
  source_specify?: string;
}

function OnboardingStep3({ onBack, selectedPlan }: OnboardingStep3Props) {
  const { backendClient, activeTeam, setOnboardingComplete } = useGlobal();
  const requirePayment = Number(selectedPlan?.price_per_month) > 0;

  const onboardUser = async (values: OnboardingStep3FormValues) => {
    if (backendClient) {
      const promise = backendClient.users.onboardUser({
        requestBody: {
          source: values.source,
          source_specify: values.source === "Other" ? values.source_specify : undefined,
          complete: true,
        },
      });
      toast.promise(promise, {
        pending: "Submitting...",
        success: "Account setup complete!",
        error: "Failed to set up account.",
      });
      await promise;
    }
  };

  const checkout = async () => {
    if (backendClient && activeTeam && selectedPlan) {
      const promise = backendClient.lemonSqueezy.createCheckout({ teamId: activeTeam.id, planId: selectedPlan.id });
      toast.promise(promise, {
        pending: "Creating checkout session...",
        success: "Redirecting to checkout...",
        error: "Failed to create checkout session.",
      });
      const url = await promise;
      try {
        const urlWithAffRef = window.LemonSqueezy.Url.Build(url);
        window.location.href = urlWithAffRef;
      } catch (e) {
        window.location.href = url;
      }
    }
  };

  return (
    <Formik
      initialValues={{ source: "", source_specify: "" }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onboardUser(values);
          if (requirePayment && selectedPlan) {
            await checkout();
          } else {
            setOnboardingComplete(true);
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, values, errors, touched }) => (
        <>
          <Modal.Body>
            <div className="small text-muted mb-4">Let's help each other get the most out of our new relationship.</div>
            <Form onSubmit={handleSubmit}>
              <BootstrapForm.Group controlId="formSource">
                <BootstrapForm.Label>Where did you first hear about Apitally?</BootstrapForm.Label>
                <Field name="source">
                  {({ field }: FieldProps<string>) => (
                    <BootstrapForm.Select isInvalid={!!errors.source && !!touched.source} {...field}>
                      <option value="" disabled>
                        Select option...
                      </option>
                      <option>Word of mouth</option>
                      <option>Search engine</option>
                      <option>Apitally blog</option>
                      <option>Advertisement</option>
                      <optgroup label="Social media">
                        <option>X (Twitter)</option>
                        <option>Reddit</option>
                        <option>LinkedIn</option>
                      </optgroup>
                      <optgroup label="Framework websites">
                        <option>FastAPI website</option>
                        <option>Starlette website</option>
                        <option>Fastify website</option>
                      </optgroup>
                      <option>Other</option>
                    </BootstrapForm.Select>
                  )}
                </Field>
                <BootstrapForm.Control.Feedback type="invalid">{errors.source}</BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
              <BootstrapForm.Group controlId="formSourceSpecify" className="mt-2" hidden={values.source !== "Other"}>
                <Field name="source_specify">
                  {({ field }: FieldProps<string>) => (
                    <BootstrapForm.Control type="text" placeholder="Please specify..." maxLength={64} {...field} />
                  )}
                </Field>
                <BootstrapForm.Control.Feedback type="invalid">{errors.source}</BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </Form>
          </Modal.Body>
          <Modal.Body className="border-top text-center">
            <div className="small mb-2">Become a part of the Apitally community!</div>
            <Button
              as="a"
              href="https://join.slack.com/t/apitally-community/shared_invite/zt-2b3xxqhdu-9RMq2HyZbR79wtzNLoGHrg"
              target="_blank"
              variant="outline-light"
            >
              <FontAwesomeIcon icon={faSlack} className="me-icon" />
              Join us on Slack
            </Button>
            <Button
              as="a"
              href="https://twitter.com/intent/follow?screen_name=apitally_io"
              target="_blank"
              variant="outline-light"
              className="ms-2"
            >
              <FontAwesomeIcon icon={faXTwitter} className="me-icon" />
              Follow us
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => onBack()}>
              Back
            </Button>
            <Button onClick={() => handleSubmit()} disabled={isSubmitting}>
              {requirePayment ? "Continue to payment" : "Complete set up"}
            </Button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
}

function Onboarding() {
  const { onboardingComplete, activeTeam } = useGlobal();
  const [onboardingStep, setOnboardingStep] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState<ListPlansResponseItem | undefined>();
  const skipTeamCreation = Boolean(activeTeam && !(activeTeam.role === "owner" && activeTeam.users_count === 1));

  useEffect(() => {
    document.title = "Onboarding - Apitally";
  }, []);

  return (
    <Modal show={onboardingComplete === false} className="OnboardingModal">
      <Modal.Header>
        <Modal.Title className="text-center w-100">
          <div className="py-4">
            <LogoIcon className="rounded-4" />
          </div>
          <div className="pb-4">Welcome to Apitally!</div>
          <SteppedProgressBar steps={skipTeamCreation ? 2 : 3} currentStep={onboardingStep} className="pb-2" />
        </Modal.Title>
      </Modal.Header>
      {onboardingStep === 1 && (
        <OnboardingStep1
          skipTeamCreation={skipTeamCreation}
          onComplete={() => setOnboardingStep(skipTeamCreation ? 3 : 2)}
        />
      )}
      {onboardingStep === 2 && (
        <OnboardingStep2
          onComplete={() => setOnboardingStep(3)}
          onBack={() => setOnboardingStep(1)}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
        />
      )}
      {onboardingStep === 3 && (
        <OnboardingStep3 onBack={() => setOnboardingStep(skipTeamCreation ? 1 : 2)} selectedPlan={selectedPlan} />
      )}
    </Modal>
  );
}

export default Onboarding;
